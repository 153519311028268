import request from '@/utils/request'

// 查询列表
export function selectPage(query) {
  return request({
    url: "/purchase/purInquiry/selectPage",
    method: "get",
    params: query
  });
}

// 删除
export function delData(id) {
  return request({
    url: "/purchase/purInquiry/"+id,
    method: "delete"
  });
}

// 根据id查询
export function selectOne(id) {
  return request({
    url: "/purchase/purInquiry/selectOne/"+id,
    method: "get"
  });
}

// 新增
export function addData(data) {
  return request({
    url: "/purchase/purInquiry",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/purchase/purInquiry",
    method: "put",
    data: data
  });
}

