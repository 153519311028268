<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.inquiryTitle"
                            label="询价标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入询价标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.inquiryPersonStr"
                            label="询价人员"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择询价人员' }]"
                            @click="showPop= true"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.inquiryDate"
                            label="询价日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择询价日期' }]"
                            @click="showPickerInquiry = true"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerInquiry"
                                  @confirm="onConfirmInquiry($event)"/>

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.materialName"
                            label="物料名称"
                            placeholder="点击选择物料"
                            :required="true"
                            :rules="[{ required: true, message: '请选择物料' }]"
                            @click="showPickerMaterial = true"
                    />
                    <van-field
                            v-model="formInline.materialCode"
                            label="物料编号"
                    />
                    <van-field
                            v-model="formInline.materialSpecification"
                            label="规格型号"
                    />
                    <van-field
                            v-model="formInline.materialUnit"
                            label="单位"
                    />
                    <van-field
                            v-model="formInline.price"
                            label="单价"
                    />

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.supplierName || '--' }}</div>
                            <van-icon name="delete-o" @click="handleDelete(index)"/>
                        </div>

                        <van-field
                                readonly
                                clickable
                                v-model="item.invoiceTypestr"
                                label="发票类型"
                                placeholder="请选择"
                                @click="handlePop('showPickerInvoice',index)"
                                :required="true"
                                :rules="[{ required: true, message: '请选择发票类型' }]"
                        />
                        <van-popup v-model="showPickerInvoice" position="bottom">
                            <van-picker
                                    show-toolbar
                                    :columns="invoiceOptions"
                                    value-key="dictLabel"
                                    @confirm="
                onConfirm($event,'invoiceType', 'invoiceTypestr', 'dictLabel', 'showPickerInvoice')
              "
                                    @cancel="showPickerInvoice = false"
                            />
                        </van-popup>

                        <van-field
                                v-model="item.quantity"
                                label="数量"
                                placeholder="请输入"
                                :required="true"
                                :rules="[{ required: true, message: '请输入数量' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.unitPrice"
                                label="单价(元)"
                                placeholder="请输入"
                                :required="true"
                                :rules="[{ required: true, message: '请输入单价' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.discount"
                                label="优惠金额(元)"
                                placeholder="请输入"
                                :required="true"
                                :rules="[{ required: true, message: '请输入优惠金额' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.totalMoney"
                                label="总金额"
                                placeholder="请选择"
                                disabled
                        />

                        <van-field
                                readonly
                                clickable
                                v-model="item.deliveryDate"
                                label="交货日期"
                                placeholder="点击选择日期"
                                @click="handlePop('showPickerDelivery',index)"
                        />
                        <van-calendar :min-date="minDate" v-model="showPickerDelivery"
                                      @confirm="onConfirmApply($event,item)"/>

                        <van-field
                                v-model="item.remark"
                                label="备注"
                                placeholder="请输入"
                        />

                    </div>
                    <div class="add-btn flex-hor-ver-cen" @click="showPickerSupplier = true">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加供应商
                    </div>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <Supplier :showDialog="showPickerSupplier" @handleClose="showPickerSupplier = false"
                  @submitData="submitSupplier"/>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

        <Material :showDialog="showPickerMaterial" @handleClose="showPickerMaterial = false"
                  @submitData="submitMaterial"/>
    </div>
</template>
<script>
    import {addData, selectOne, updateData} from '@/api/purchase/inquiry';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import Supplier from '@/components/supplier/index'
    import SelectOneUser from '@/components/selectOneUser/index'
    import Material from "@/components/material/index";
    import {formatDate} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";

    export default {
        components: {Material, AppendixVue, Supplier, SelectOneUser},
        data() {
            return {
                formInline: {},
                title: '新建询价',
                id: this.$route.query.id,
                type: this.$route.query.type,
                uploader: [],
                uploadParams: {
                    businessType: 'inquiry',
                    businessId: undefined,
                    file: null,
                },
                showPickerInquiry: false,
                showPickerSupplier: false,
                showPickerMaterial: false,
                showPickerInvoice: false,
                showPickerDelivery: false,
                showPop: false,
                minDate: new Date(2010, 0, 1),
                //明细数组
                dataList: [],
                invoiceOptions: [],
                //选中的明细索引
                itemIndex: null
            };
        },

        mounted() {
            //发票类型
            this.getDicts('invoice_type').then((response) => {
                this.invoiceOptions = response.data;
            });
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/purchase/purchaseInquiry');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        enterCategory: 0
                    };
                    this.title = '新建询价';
                } else {
                    let itemDetail = await selectOne( this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    this.dataList = itemDetail.infoList
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑询价';
                    } else {
                        this.title = '询价详情';
                    }
                }
            },

            //确认
            onConfirm(event, key, keyName, eventKey, visible) {
                this.dataList[this.itemIndex][key] = event['dictValue'];
                this.dataList[this.itemIndex][keyName] = event[eventKey];
                this[visible] = false;
                this.$forceUpdate()
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.inquiryPerson = activeId
                this.formInline.inquiryPersonStr = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            //询价日期确认
            onConfirmInquiry(value) {
                this.formInline.inquiryDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerInquiry = false;
            },

            //交货日期确认
            onConfirmApply(value) {
                this.dataList[this.itemIndex].deliveryDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerDelivery = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //物料提交
            submitMaterial(obj) {
                this.formInline.inquiryMaterial = obj.id
                this.formInline.materialName = obj.materialName
                this.formInline.materialCode = obj.materialCode
                this.formInline.materialSpecification = obj.materialSpecification
                this.formInline.materialUnit = obj.materialUnit
                this.formInline.price = obj.price

                this.showPickerMaterial = false
            },

            //触发明细弹窗
            handlePop(showPicker,index) {
                this[showPicker] = true;
                this.itemIndex = index
            },

            //供应商提交
            submitSupplier(id, name) {
                let oldObj = this.dataList.find(item => item.supplierId == id)
                if (!oldObj) {
                    this.dataList.push({
                        supplierId: id,
                        supplierName: name,
                        quantity: 0,
                        unitPrice: 0,
                        discount: 0,
                        totalMoney: 0,
                        deliveryDate: null,
                        remark: ''
                    });
                }
                this.showPickerSupplier = false
            },

            //删除节点
            handleDelete(index) {
                this.dataList.splice(index, 1);
            },

            //单条明细总金额计算
            itemTotalPriceCal(item) {
                item.totalMoney = ((item.quantity ? item.quantity : 0) * (item.unitPrice ? item.unitPrice : 0)).toFixed(2) - Number(item.discount)
            },

            async onSubmit() {
                if (this.dataList.length == 0) {
                    this.$toast.fail('请添加供应商');
                    return;
                }

                this.dataList.forEach(item => {
                    delete item.createBy
                    delete item.createTime
                    delete item.updateBy
                    delete item.updateTime
                    delete item.companyId
                })

                this.formInline.infoJson = JSON.stringify(this.dataList)
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
