<template>
    <div class="app-container">
        <van-popup v-model="popDialog" style="width: 80%; padding: 20px; border-radius: 8px" @close="handleClose">
            <van-tree-select
                    class="treeSelect mrg-b-30"
                    :items="items"
                    :active-id.sync="activeId"
                    :main-active-index.sync="activeIndex"
                    @click-item="clickItem"
            />
            <van-button style="width: 100%; height: 40px" block type="info" @click="popSubmit"
            >提交
            </van-button
            >
        </van-popup>
    </div>
</template>

<script>
    import {treeAllDeptAndUser} from "@/api/system/dept";

    export default {
        props: {
            showPop: Boolean,
            isMultiple: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                popDialog: false,
                activeIndex: 0,
                activeId: null,
                items: [],
                names: ''
            };
        },
        watch: {
            showPop: {
                handler() {
                    this.popDialog = this.showPop
                    if (this.showPop) {
                        this.findUserAndDept()
                    }
                },
                immediate: true,
                deep: true,
            },
            isMultiple: {
                handler() {
                    if (this.isMultiple) {
                        this.activeId = []
                    } else {
                        this.activeId = null
                    }
                },
                immediate: true,
                deep: true,
            }
        },
        methods: {
            //查询用户和部门
            async findUserAndDept() {
                const resultData = await treeAllDeptAndUser();
                this.items = resultData.data;

                this.items.forEach((item) => {
                    this.packItems(item);
                });
            },

            //递归label赋值给text
            packItems(item) {
                item.text = item.label;
                if (item.children) {
                    item.children.forEach((detail) => {
                        this.packItems(detail);
                    });
                }
            },

            clickItem(data) {
                if (this.isMultiple && this.names != '') {
                    this.names = this.names + ',' + data.text
                } else {
                    this.names = data.text
                }
            },

            //提交
            popSubmit() {
                if (!this.activeId) {
                    this.$toast.fail('请选择用户');
                }
                this.popDialog = false
                this.$emit('popSubmit', this.activeId, this.names);
            },

            //取消/关闭弹窗
            handleClose() {
                this.popDialog = false
                this.$emit('handleClose');
            },
        },
    };
</script>
