import request from "@/utils/request";

// 查询供应商列表
export function selectPage(query) {
  return request({
    url: "/purchase/supplier/selectPage",
    method: "get",
    params: query
  });
}

// 查询所有供应商
export function selectAllSupplier() {
  return request({
    url: "/purchase/supplier/selectAll",
    method: "get"
  });
}

// 查询供应商明细
export function selectOne(query) {
  return request({
    url: "/purchase/supplier/selectOne",
    method: "get",
    params: query
  });
}

// 删除供应商
export function delData(id) {
  return request({
    url: "/purchase/supplier/"+id,
    method: "delete"
  });
}

// 新增供应商
export function addData(data) {
  return request({
    url: "/purchase/supplier",
    method: "post",
    data: data
  });
}

// 修改供应商
export function updateData(data) {
  return request({
    url: "/purchase/supplier",
    method: "put",
    data: data
  });
}

//查询所有分类
export function selectAllCategroy() {
  return request({
    url: "/purchase/supplierCategory",
    method: "get"
  });
}

// 新增分类
export function addCategory(data) {
  return request({
    url: "/purchase/supplierCategory",
    method: "post",
    data: data
  });
}

// 修改分类
export function updateCategory(data) {
  return request({
    url: "/purchase/supplierCategory",
    method: "put",
    data: data
  });
}

// 删除
export function delCategory(id) {
  return request({
    url: "/purchase/supplierCategory/"+id,
    method: "delete"
  });
}

// 查询行业类别
export function selectAllIndusty() {
  return request({
    url: "/system/user/selectAllIndusty",
    method: "get"
  });
}

// 根据id查询账户明细
export function findAccountDetail(id) {
  return request({
    url: "/cw/account/"+id,
    method: "get"
  });
}
